<template>
  <div id="reservationEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="customersData">
              <div class="vx-col w-full">
                <h1 v-if="reservationData && reservationData.id">Uredi rezervacijo</h1>
                <h1 v-if="reservationData && !reservationData.id">Dodaj rezervacijo</h1>
              </div>
            </div>

            <div class="vx-row mb-2" v-if="customersData && reservationData">

              <!-- prevent making changes in history -->
              <div class="vx-col w-full my-3"
                   v-if="!$globalFunctions.getCurrentUser().isAppAdmin && reservationData.departureTime && moment(reservationData.departureTime).isBefore(moment().subtract(12, 'hours')) ">
                <vs-alert :active="!$v.customer.required" :color="'danger'"
                          title="Urejanje in dodajanje rezervacij v preteklosti ni mogoče">
                  V kolikor so potrebni popravki takšne rezervacije, se obrnite na vodstvo Zavoda Sopotniki za pomoč.
                </vs-alert>
              </div>

              <div class="vx-col w-full">
                <label class="vs-input--label">
                  {{
                    !reservationData.customerId || reservationData.customer.gender === 'male' ? 'Uporabnik' :
                      'Uporabnica'
                  }}
                </label>
                <v-select :filter="fuseSearch" :options="customersData" class="" v-model="customer"
                          :placeholder="!reservationData.customerId || reservationData.customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica'"
                          :getOptionLabel="option => option.first_name + '' + option.last_name"
                          @input="onCustomerChange">
                  <template v-slot:option="option">
                    <vs-list-item
                      :title="option.first_name + ' ' + option.last_name"
                      :subtitle="option.addresses.quickSearch">
                      <template slot="avatar">
                        <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam"
                                   :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:selected-option="option">
                    <vs-list-item
                      class="d-block"
                      :title="option.first_name + ' ' + option.last_name"
                      :subtitle="option.addresses.quickSearch">
                      <template slot="avatar">
                        <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam"
                                   :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                      </template>
                    </vs-list-item>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                  </template>
                </v-select>

                <div class="flex mt-1" v-if="$v.customer.$dirty">
                  <div class="w-full">
                    <vs-alert :active="!$v.customer.required" :color="'danger'">
                      Polje "{{ !customer || customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica' }}" ne sme biti
                      prazno.
                    </vs-alert>
                  </div>
                </div>

                <div class="clear clearfix"></div>


                <vs-alert
                  v-if="customer"
                  class="mt-5"
                  color="primary"
                  :title="'Opombe o ' + (customer.gender === 'male' ? 'uporabniku' : 'uporabnici')"
                  active="true">

                  <!--                  {{ customer.gender === 'male' ? 'Uporabnik cepljen' : 'Uporabnica cepljena' }} proti COVID-19: <span-->
                  <!--                  v-if="customer.vaccinated === null" class="font-bold"><vs-icon icon="highlight_off"></vs-icon> ni podatka</span>-->
                  <!--                  <span v-if="customer.vaccinated === false || customer.vaccinated === 0" class="font-bold text-bold"><vs-icon-->
                  <!--                    icon="highlight_off" class="text-danger"></vs-icon> NE</span>-->
                  <!--                  <span v-if="customer.vaccinated === true || customer.vaccinated === 1" class="font-bold"><vs-icon-->
                  <!--                    icon="check_circle" class="text-success"></vs-icon> DA</span>-->
                  <div>
                    {{ customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica' }} ima parkirno karto za invalide: <span
                    v-if="customer.ownsParkingPermitForDisabled === null" class="font-bold"><vs-icon
                    icon="highlight_off"></vs-icon> ni podatka</span>
                    <span
                      v-if="customer.ownsParkingPermitForDisabled === false || customer.ownsParkingPermitForDisabled === 0"
                      class="font-bold text-bold"><vs-icon
                      icon="highlight_off" class="text-danger"></vs-icon> NE</span>
                    <span
                      v-if="customer.ownsParkingPermitForDisabled === true || customer.ownsParkingPermitForDisabled === 1"
                      class="font-bold"><vs-icon
                      icon="check_circle" class="text-success"></vs-icon> DA</span>
                  </div>
                  <div>
                    {{ customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica' }} uporablja pripomoček pri hoji
                    (hodulja, zložljiv voziček, bergle): <span
                    v-if="customer.usesWalkingAid === null" class="font-bold"><vs-icon icon="highlight_off"></vs-icon> ni podatka</span>
                    <span v-if="customer.usesWalkingAid === false || customer.usesWalkingAid === 0"
                          class="font-bold text-bold"><vs-icon
                      icon="highlight_off" class="text-danger"></vs-icon> NE</span>
                    <span v-if="customer.usesWalkingAid === true || customer.usesWalkingAid === 1" class="font-bold"><vs-icon
                      icon="check_circle" class="text-success"></vs-icon> DA</span>
                  </div>
                  <div>
                    {{ customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica' }} pri opravkih potrebuje spremstvo:
                    <span
                      v-if="customer.needsEscort === null" class="font-bold"><vs-icon icon="highlight_off"></vs-icon> ni podatka</span>
                    <span v-if="customer.needsEscort === false || customer.needsEscort === 0"
                          class="font-bold text-bold"><vs-icon
                      icon="highlight_off" class="text-danger"></vs-icon> NE</span>
                    <span v-if="customer.needsEscort === true || customer.needsEscort === 1" class="font-bold"><vs-icon
                      icon="check_circle" class="text-success"></vs-icon> DA</span>
                  </div>

                  <span v-if="customer.notes">
                    <br/>
                    {{ customer.notes }}
                  </span>
                </vs-alert>

                <vs-alert
                  v-if="customer && (customer.coordinator_notes || customer.hasSpecialBehaviouralNeeds)"
                  class="mt-3"
                  color="warning"
                  :title="'Koordinatorjeve interne opombe o ' + (customer.gender === 'male' ? 'uporabniku' : 'uporabnici')"
                  active="true">
                    <div>
                        {{ customer.gender === 'male' ? 'Uporabnik' : 'Uporabnica' }} ima posebnosti pri vedenju (znaki
                        demence ipd.): <span
                            v-if="customer.hasSpecialBehaviouralNeeds === null" class="font-bold"><vs-icon
                            icon="highlight_off"></vs-icon> ni podatka</span>
                        <span
                                v-if="customer.hasSpecialBehaviouralNeeds === false || customer.hasSpecialBehaviouralNeeds === 0"
                                class="font-bold text-bold"><vs-icon
                                icon="highlight_off" class="text-danger"></vs-icon> NE</span>
                        <span
                                v-if="customer.hasSpecialBehaviouralNeeds === true || customer.hasSpecialBehaviouralNeeds === 1"
                                class="font-bold"><vs-icon
                                icon="check_circle" class="text-success"></vs-icon> DA</span>
                    </div>

                    <span>
                      <br/>
                      {{ customer.coordinator_notes }}
                    </span>
                </vs-alert>
              </div>
            </div>


            <div v-if="reservationData">
              <div class="grid grid-cols-3 gap-2 mt-3">
                <div>
                  <label class="vs-input--label">Datum prevoza</label>
                  <datepicker placeholder="Datum rezervacije" :language="slSI" :format="customDateFormatter"
                              :monday-first="true"
                              @input="onDateChange" v-model="reservationData.departureTime"></datepicker>

                  <div class="flex mt-1" v-if="$v.reservationData.departureTime.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.reservationData.departureTime.required" :color="'danger'">
                        Polje "Datum prevoza" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>
                </div>
                <div>
                  <label class="vs-input--label">Ura odhoda na destinacijo
                    (A
                    <vs-icon icon="keyboard_arrow_right"></vs-icon>
                    B)
                  </label>
                  <flat-pickr :config="configTimePicker" v-model="departureTime" placeholder="Ura odhoda na destinacijo"
                              class="w-full"/>

                  <div class="flex mt-1" v-if="$v.reservationData.departureTime.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.reservationData.departureTime.required" :color="'danger'">
                        Polje "Ura odhoda na destinacijo" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>

                </div>
                <div>
                  <label class="vs-input--label">Ura odhoda na povratek (B
                    <vs-icon icon="keyboard_arrow_right"></vs-icon>
                    C)
                  </label>
                  <flat-pickr :config="configTimePicker" v-model="arrivalTime" placeholder="Ura odhoda na povratek"
                              class="w-full"/>

                  <div class="flex mt-1" v-if="$v.reservationData.arrivalTime.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.reservationData.arrivalTime.required" :color="'danger'">
                        Polje "Ura odhoda na povratek" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>

                </div>
              </div>

              <div class="grid grid-cols-1 mt-5">
                <label for="reservationCoordinated" class="vs-input--label">Termin prevoza dodatno skoordiniran:
                  <br/><small>(označi, če smo npr.
                    uporabniku prestavili termin pri zdravniku, da smo mu lahko ponudili prevoz)</small></label>
                <vs-switch color="success" v-model="reservationData.reservationCoordinated"
                           id="reservationCoordinated">
                  <span slot="on">DA</span>
                  <span slot="off">NE</span>
                </vs-switch>
              </div>

              <div class="grid grid-cols-1 mt-5">
                <label for="sharedTransportation" class="vs-input--label">Prevoz s sopotništvom:
                  <br/><small>(označi, če si je prevoz oz. del poti delilo več uporabnikov)</small></label>
                <vs-switch color="success" v-model="reservationData.sharedTransportation"
                           id="sharedTransportation">
                  <span slot="on">DA</span>
                  <span slot="off">NE</span>
                </vs-switch>
              </div>

              <div class="flex mt-5">
                <div class="w-full">
                  <label class="vs-input--label">Opombe rezervacije</label>
                  <vs-textarea class="w-full" rows="6" v-model="reservationData.reservationsNotes"/>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class=""><img class="markerIcon" alt=""
                                      src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EA%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                      draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
                      <span class="pl-8">Mesto odhoda</span></h4>
                    <div class="clearfix"></div>

                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="departureAddress" class="vs-input--label">Naslov mesta odhoda</label>

                      <gmap-autocomplete
                        ref="departureAddress"
                        id="departureAddress"
                        @place_changed="(data) => { getAddressData(data, 'departure') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="reservationData.departure ? reservationData.departure.quickSearch : ''"
                        :options="{componentRestrictions: { country: 'si' }}"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>

                      <div class="flex mt-1" v-if="$v.reservationData.departure.quickSearch.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.reservationData.departure.quickSearch.required" :color="'danger'">
                            Polje "Naslov mesta odhoda" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv mesta odhoda (ime naselja, vasi oz. ustanove)"
                                v-model="reservationData.departure.addressName"
                                @input="(val) => { reservationData.departure.addressName = val; $forceUpdate(); }"
                                @blur="copyAddressName"/>
                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova mesta odhoda
                        </div>

                        <div class="collapseBody mt-5">

                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="reservationData.departure.route"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.route.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.route.required" :color="'danger'">
                                    Polje "Ulica" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="reservationData.departure.streetNumber"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.streetNumber.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.streetNumber.required"
                                            :color="'danger'">
                                    Polje "Hišna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="reservationData.departure.postalTown"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.postalTown.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.postalTown.required"
                                            :color="'danger'">
                                    Polje "Naziv pošte" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="reservationData.departure.postalCode"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.postalCode.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.postalCode.required"
                                            :color="'danger'">
                                    Polje "Poštna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina" v-model="reservationData.departure.municipality"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.municipality.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.municipality.required"
                                            :color="'danger'">
                                    Polje "Občina" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="reservationData.departure.country"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.country.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.country" :color="'danger'">
                                    Polje "Država" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class=""><img class="markerIcon" alt=""
                                      src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EB%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                      draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
                      <span class="pl-8">Mesto prihoda</span></h4>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="destinationAddress" class="vs-input--label">Naslov mesta prihoda</label>

                      <gmap-autocomplete
                        ref="destinationAddress"
                        id="destinationAddress"
                        @place_changed="(data) => { getAddressData(data, 'destination') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="reservationData.destination ? reservationData.destination.quickSearch : ''"
                        :options="{componentRestrictions: { country: 'si' }}"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>

                      <div class="flex mt-1" v-if="$v.reservationData.destination.quickSearch.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.reservationData.destination.quickSearch.required" :color="'danger'">
                            Polje "Naslov mesta prihoda" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv mesta destinacije (ime naselja, vasi oz. ustanove)"
                                v-model="reservationData.destination.addressName"/>
                    </div>

                    <div class="vx-col w-full mt-5" v-if="reservationPurposesData">
                      <label for="destinationAddress" class="vs-input--label">Glavni namen poti</label>
                      <v-select :filter="fuseSearchName" :options="reservationPurposesData" class=""
                                v-model="reservationPurpose" placeholder="Glavni namen poti"
                                :getOptionLabel="option => option.name">
                        <template v-slot:option="option">
                          <vs-list-item
                            :title="option.name">
                            <template slot="avatar">
                              <vs-avatar size="large" :icon="$globalFunctions.getReservationPurposeIcon(option.id)"
                                         class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:selected-option="option">
                          <vs-list-item
                            class="d-block"
                            :title="option.name">
                            <template slot="avatar">
                              <vs-avatar size="large" :icon="$globalFunctions.getReservationPurposeIcon(option.id)"
                                         class="mr-5"/>
                            </template>
                          </vs-list-item>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                          </template>
                          <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                        </template>
                      </v-select>

                      <div class="flex mt-1" v-if="$v.reservationPurpose.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.reservationPurpose.required" :color="'danger'">
                            Polje "Glavni namen poti" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova mesta prihoda
                        </div>

                        <div class="collapseBody mt-5">
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="reservationData.destination.route"/>

                              <div class="flex mt-1" v-if="$v.reservationData.destination.route.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.destination.route.required" :color="'danger'">
                                    Polje "Ulica" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="reservationData.destination.streetNumber"/>

                              <div class="flex mt-1" v-if="$v.reservationData.destination.streetNumber.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.destination.streetNumber.required"
                                            :color="'danger'">
                                    Polje "Hišna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="reservationData.destination.postalTown"/>

                              <div class="flex mt-1" v-if="$v.reservationData.destination.postalTown.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.destination.postalTown.required"
                                            :color="'danger'">
                                    Polje "Naziv pošte" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="reservationData.destination.postalCode"/>

                              <div class="flex mt-1" v-if="$v.reservationData.destination.postalCode.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.destination.postalCode.required"
                                            :color="'danger'">
                                    Polje "Poštna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina"
                                        v-model="reservationData.destination.municipality"/>

                              <div class="flex mt-1" v-if="$v.reservationData.destination.municipality.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.destination.municipality.required"
                                            :color="'danger'">
                                    Polje "Občina" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="reservationData.destination.country"/>

                              <div class="flex mt-1" v-if="$v.reservationData.destination.country.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.destination.country" :color="'danger'">
                                    Polje "Država" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>
              </div>

              <div class="vx-card mt-5">
                <div class="vx-card__header">
                  <div class="vx-card__title">
                    <h4 class="">
                      <img class="markerIcon" alt=""
                           src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EC%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                           draggable="false" usemap="#gmimap2" style="width: 27px; height: 43px;">
                      <span class="pl-8">Mesto povratka</span></h4>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body">

                    <div class="grid grid-cols-1">
                      <label for="destinationAddress" class="vs-input--label">Naslov mesta povratka</label>

                      <gmap-autocomplete
                        ref="arrivalAddress"
                        id="arrivalAddress"
                        @place_changed="(data) => { getAddressData(data, 'arrival') }"
                        placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                        :value="reservationData.arrival ? reservationData.arrival.quickSearch : ''"
                        :options="{componentRestrictions: { country: 'si' }}"
                        :select-first-on-enter="true"
                        class="vs-inputx vs-input--input normal"></gmap-autocomplete>

                      <div class="flex mt-1" v-if="$v.reservationData.arrival.quickSearch.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.reservationData.arrival.quickSearch.required" :color="'danger'">
                            Polje "Naslov mesta povratka" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <vs-input class="w-full" label="Naziv mesta povratka (ime naselja, vasi oz. ustanove)"
                                v-model="reservationData.arrival.addressName"/>
                    </div>

                    <vs-collapse ref="collapsible" class="mt-5">

                      <vs-collapse-item class="addressCollapse">
                        <div slot="header" class="p-0 text-primary">
                          Podrobnosti naslova mesta prihoda
                        </div>

                        <div class="collapseBody mt-5">
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Ulica" v-model="reservationData.arrival.route"/>

                              <div class="flex mt-1" v-if="$v.reservationData.arrival.route.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.arrival.route.required" :color="'danger'">
                                    Polje "Ulica" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Hišna številka"
                                        v-model="reservationData.arrival.streetNumber"/>

                              <div class="flex mt-1" v-if="$v.reservationData.arrival.streetNumber.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.arrival.streetNumber.required"
                                            :color="'danger'">
                                    Polje "Hišna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Naziv pošte"
                                        v-model="reservationData.arrival.postalTown"/>

                              <div class="flex mt-1" v-if="$v.reservationData.arrival.postalTown.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.arrival.postalTown.required" :color="'danger'">
                                    Polje "Naziv pošte" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Poštna številka"
                                        v-model="reservationData.arrival.postalCode"/>

                              <div class="flex mt-1" v-if="$v.reservationData.arrival.postalCode.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.arrival.postalCode.required" :color="'danger'">
                                    Polje "Poštna številka" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="flex mb-4">
                            <div class="w-3/4">
                              <vs-input class="w-full" label="Občina" v-model="reservationData.arrival.municipality"/>

                              <div class="flex mt-1" v-if="$v.reservationData.departure.municipality.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.departure.municipality.required"
                                            :color="'danger'">
                                    Polje "Občina" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                            <div class="w-1/4 ml-5">
                              <vs-input class="w-full" label="Država" v-model="reservationData.arrival.country"/>

                              <div class="flex mt-1" v-if="$v.reservationData.arrival.country.$dirty">
                                <div class="w-full">
                                  <vs-alert :active="!$v.reservationData.arrival.country" :color="'danger'">
                                    Polje "Država" ne sme biti prazno.
                                  </vs-alert>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vs-collapse-item>
                    </vs-collapse>
                  </div>
                </div>
              </div>

              <div class="vx-row mt-5 mb-2" v-if="volunteersData && reservationData && reservationData.departureTime">

                <div class="vx-col w-full">
                  <label class="vs-input--label">
                    Prostovoljec, ki bo opravil prevoz
                  </label>
                  <v-select :filter="fuseSearch" :options="volunteersData" class="" v-model="volunteer"
                            placeholder="Prostovoljec"
                            :getOptionLabel="option => option.first_name + '' + option.last_name">
                    <template v-slot:option="option">
                      <vs-list-item
                        :title="option.first_name + ' ' + option.last_name">
                        <template slot="avatar">
                          <div class="avatarWithStatus mr-5">
                            <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)"/>
                            <vs-avatar v-if="option.active === 1" size="small" class="status" color="success"
                                       icon="check_circle"/>
                            <vs-avatar v-if="option.active === 0" size="small" class="status" color="danger"
                                       icon="radio_button_unchecked"/>
                          </div>
                        </template>
                      </vs-list-item>
                    </template>

                    <template v-slot:selected-option="option">
                      <vs-list-item
                        class="d-block"
                        :title="option.first_name + ' ' + option.last_name">
                        <template slot="avatar">
                          <div class="avatarWithStatus mr-5">
                            <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)"/>
                            <vs-avatar v-if="option.active === 1" size="small" class="status" color="success"
                                       icon="check_circle"/>
                            <vs-avatar v-if="option.active === 0" size="small" class="status" color="danger"
                                       icon="radio_button_unchecked"/>
                          </div>
                        </template>
                      </vs-list-item>
                    </template>

                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                    </template>
                  </v-select>

                  <div class="clear clearfix"></div>
                  <vs-alert
                    v-if="volunteer && volunteer.notes"
                    class="mt-5"
                    color="primary"
                    :title="'Opombe o ' + (volunteer.gender === 'male' ? 'prostovoljcu' : 'prostovoljki')"
                    active="true">
                    {{ volunteer.notes }}
                  </vs-alert>
                </div>
              </div>

              <div class="vx-row mt-5 mb-2" v-if="carsData && carsData.length > 1 && reservationData.departureTime">

                <div class="vx-col w-full">
                  <label class="vs-input--label">
                    Vozilo, s katerim bo prevoz opravljen
                  </label>
                  <v-select :filter="fuseSearchName" :options="carsData" v-model="car" placeholder="Vozilo"
                            :getOptionLabel="option => option.name">
                    <template v-slot:option="option">
                      <vs-list-item
                        :title="option.name"
                        :subtitle="option.registrationNumber">
                        <template slot="avatar">
                          <vs-avatar size="large" class="mr-5" :style="'background-color:' + option.color"
                                     :text="option.name"/>
                        </template>
                      </vs-list-item>
                    </template>

                    <template v-slot:selected-option="option">
                      <vs-list-item
                        :title="option.name"
                        :subtitle="option.registrationNumber">
                        <template slot="avatar">
                          <vs-avatar size="large" class="mr-5" :style="'background-color:' + option.color"
                                     :text="option.name"/>
                        </template>
                      </vs-list-item>
                    </template>

                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                    </template>
                  </v-select>

                </div>
              </div>

              <div class="grid grid-cols-1 mt-5" v-if="this.$globalFunctions.getCurrentUnit().id === 56">
                <label for="toyota_event" class="vs-input--label">Dogodek organiziran v sodelovanju s Toyoto</label>
                <vs-switch color="success" v-model="reservationData.toyota_event"
                           id="toyota_event">
                  <span slot="on">DA</span>
                  <span slot="off">NE</span>
                </vs-switch>
              </div>

              <div class="flex mt-5">
                <div class="w-full">
                  <label class="vs-input--label">Opombe koordinatorja <small><em>(te opombe niso vidne drugim
                    osebam)</em></small></label>
                  <vs-textarea class="w-full" rows="6" v-model="reservationData.coordinatorsNotes"/>
                </div>
              </div>

            </div>
            <!--{{reservationData}}-->

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <template>

            <div class="vx-card mt-16" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Načrt poti</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-0">

                  <div
                    v-if="reservationData && reservationData.departure.quickSearch && reservationData.destination.quickSearch">
                    <vs-collapse class="p-0">
                      <vs-collapse-item>
                        <div slot="header">
                          <vs-list-item v-if="routeDistanceTotal" title="Skupna razdalja"
                                        :subtitle="Math.round(routeDistanceTotal/1000).toString() + ' km'">
                            <template slot="avatar">
                              <vs-avatar text="skupaj"/>
                            </template>
                          </vs-list-item>
                        </div>

                        <vs-list-item v-if="routeDistanceAB" title="Razdalja od A do B"
                                      :subtitle="Math.round(routeDistanceAB/1000).toString() + ' km'">
                          <template slot="avatar">
                            <vs-avatar text="AB"/>
                          </template>
                        </vs-list-item>
                        <vs-list-item v-if="routeDistanceBC" title="Razdalja od B do C"
                                      :subtitle="Math.round(routeDistanceBC/1000).toString() + ' km'">
                          <template slot="avatar">
                            <vs-avatar text="BC"/>
                          </template>
                        </vs-list-item>


                      </vs-collapse-item>

                      <vs-collapse-item>
                        <div slot="header">
                          <vs-list-item v-if="routeTravelTimeTotal" title="Skupni čas potovanja"
                                        :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeTotal)">
                            <template slot="avatar">
                              <vs-avatar text="skupaj"/>
                            </template>
                          </vs-list-item>
                        </div>

                        <vs-list-item v-if="routeTravelTimeAB" title="Čas potovanja od A do B"
                                      :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeAB)">
                          <template slot="avatar">
                            <vs-avatar text="AB"/>
                          </template>
                        </vs-list-item>
                        <vs-list-item v-if="routeTravelTimeBC" title="Čas potovanja od B do C"
                                      :subtitle="$globalFunctions.formatSecondsToReadableDuration(routeTravelTimeBC)">
                          <template slot="avatar">
                            <vs-avatar text="BC"/>
                          </template>
                        </vs-list-item>

                      </vs-collapse-item>
                    </vs-collapse>
                  </div>

                  <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">
                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                                      @closeclick="infoWinOpen=false">

                    </gmap-info-window>
                    <DirectionsRenderer/>
                  </gmap-map>

                </div>
              </div>
            </div>

            <!--            <div class="vx-card mt-16" style="overflow: hidden" v-if="$globalFunctions.getCurrentUser().isAppAdmin">-->
            <!--              <div class="vx-card__header">-->
            <!--                <h4 class="text-primary mb-4">Intermodalnost</h4>-->
            <!--              </div>-->
            <!--              <div class="vx-card__collapsible-content">-->
            <!--                <div class="vx-card__body p-6">-->
            <!--                  <IntermodalTransport :origin="reservationData.departure.quickSearch" :destination="reservationData.destination.quickSearch"></IntermodalTransport>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->


          </template>
        </div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>

        <!-- prevent making changes in history -->
        <div class="w-1/2"
             v-if="$globalFunctions.getCurrentUser().isAppAdmin || !reservationData.departureTime || (reservationData.departureTime && !moment(reservationData.departureTime).isBefore(moment().subtract(12, 'hours')))">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="reservationData && reservationData.id">
              <vs-button size="large" color="danger" @click="beforeDeleteReservation" icon="delete">Izbriši</vs-button>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="success" icon="save" @click="saveReservation">Shrani</vs-button>
            </vs-col>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import IntermodalTransport from "@/components/Sopotniki/reservations/IntermodalTransport";
import vSelect from "vue-select";
import Fuse from 'fuse.js';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';
import {minLength, required} from "vuelidate/lib/validators";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'reservation',

  components: {
    IntermodalTransport,
    DirectionsRenderer,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
  },
  props: {
    reservationId: Number
  },
  data() {
    return {
      sir: sir,
      madam: madam,
      slSI: slSI,

      // reservationData: null,
      routeDistanceAB: null,
      routeDistanceBC: null,
      routeDistanceTotal: null,
      routeTravelTimeAB: null,
      routeTravelTimeBC: null,
      routeTravelTimeTotal: null,
      center: {lat: 46.0569, lng: 14.5058},
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {width: 0, height: -35}
      },

      customersData: null,
      customer: null,

      volunteersData: null,
      copyOfVolunteersData: null,
      volunteer: null,

      reservationPurposesData: null,
      reservationPurpose: null,

      carsData: this.$globalFunctions.getCurrentUnit().cars.filter((item) => !item.name.includes('[ni več aktivno]')),
      car: null,

      date: new Date(),
      departureTime: null, //this.moment().hours('08').minutes('0').valueOf(),
      arrivalTime: null, //this.moment().hours('10').minutes('0').valueOf(),

      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        allowInput: true,
        altFormat: 'H:i',
        altInput: true,
        time_24hr: true,
        dateFormat: 'H:i',
        minuteIncrement: 15
      },

      reservationData: {
        "id": null,
        "disabled": null,
        "deleted": null,
        "customerId": null,
        "unitId": null,
        "departureTime": null,
        "departureAddressId": null,
        "arrivalAddressId": null,
        "destinationId": null,
        "destination": {},
        "arrivalTime": null,
        "arrival_address_id": null,
        "carId": null,
        "reservationsNotes": null,
        "routeDistance": null,
        "routeTime": null,
        "routeCost": null,
        "agreedDonation": null,
        "createdAt": null,
        "updatedAt": null,
        "receivedDonation": null,
        "volunteerId": null,
        "reservation_purpose": null,
        "reservationCoordinated": false,
        "sharedTransportation": false,
        "waitingForReview": false,
        "arrival": {},
        "unit": {},
        "customer": {},
        "car": {},
        "purpose": {},
        "departure": {},
        "volunteer": {}
      },

      intermodalTransportationThreshold: 15 * 60, // threshold for arriving to bus/train station on time
    }
  },

  validations: {
    customer: {
      required
    },
    reservationPurpose: {
      required
    },
    reservationData: {
      departureTime: {
        required
      },
      arrivalTime: {
        required
      },
      departure: {
        quickSearch: {
          required
        },
        route: {
          required
        },
        streetNumber: {
          required
        },
        postalCode: {
          required
        },
        postalTown: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      },
      destination: {
        quickSearch: {
          required
        },
        route: {
          required
        },
        streetNumber: {
          required
        },
        postalCode: {
          required
        },
        postalTown: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      },
      arrival: {
        quickSearch: {
          required
        },
        route: {
          required
        },
        streetNumber: {
          required
        },
        postalCode: {
          required
        },
        postalTown: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      }
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p1 = _this.getCustomers();
    let p2 = _this.getVolunteers();
    let p3 = _this.getReservationPurposes();

    Promise.all([p1, p2, p3]).then(() => {
      if (_this.reservationId) {
        _this.getReservation().then(() => {
          _this.getDailyReports().then(() => {
            _this.$vs.loading.close();

            if (_this.$route.params.createdViaDuplicateMethod) {
              Vue.$confirm({
                title: 'Rezervacija je bila ustvarjena preko funkcionalnosti \'Podvoji\'!',
                message: 'Datum in čas odhoda in prihoda sta bila prekopirana iz prvotne rezervacije, polje \'Prostovoljec, ki bo opravil prevoz\' je bilo izpraznjeno. Prosim, vnesi pravilne podatke o rezervaciji in shrani spremembe!',
                button: {
                  yes: 'OK',
                },
                callback: confirm => {

                }
              });
            }

          });
        });
      } else {

        _this.departureTime = _this.moment().hours('08').minutes('0').format('HH:mm');
        _this.arrivalTime = _this.moment().hours('10').minutes('0').format('HH:mm');

        _this.getDailyReports().then(() => {
          _this.$vs.loading.close();

          if (_this.$route.params.intermodalReservationData) {
            _this.prefillDataFromIntermodalReservation();
          }

          geoCoder = new google.maps.Geocoder();
          geoCoder.geocode({'address': this.$globalFunctions.getCurrentUnit().locationForContracts + ', Slovenija'}, function (results, status) {
            if (status === 'OK') {
              _this.center = results[0].geometry.location;
            } else {
              console.error('Geocode was not successful for the following reason: ' + status);
            }
          });
        });

      }
    });
  },

  watch: {
    reservationId: {
      handler(val, oldVal) {
        console.log("CHANGE!!!!");
        console.log("val", val);
        this.reservationData = null;
        this.routeDistanceAB = null;
        this.routeDistanceBC = null;
        this.routeDistanceTotal = null;
        this.routeTravelTimeAB = null;
        this.routeTravelTimeBC = null;
        this.routeTravelTimeTotal = null;

        if (val) {
          this.getReservation();
        }
      }
    }
  },

  methods: {
    async getCustomers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
        .then((res) => {
          _this.customersData = _.filter(res.data.data, {active: 1});
          console.log("customersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getVolunteers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
        .then((res) => {
          _this.volunteersData = res.data.data;
          console.log("volunteersData ----> : ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getReservationPurposes() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'reservationPurpose')
        .then((res) => {
          _this.reservationPurposesData = res.data.data;
          console.log("reservationPurposesData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getDailyReports() {
      const _this = this;

      if (!_this.reservationData.departureTime) {
        return
      }

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'dailyReportsday/unit/' + _this.$globalFunctions.getCurrentUnit().id + '/' + _this.moment(_this.reservationData.departureTime).valueOf())
        .then((res) => {

          console.log("volunteersData: ", _this.volunteersData);
          console.log("dailyReports: ", res.data.data);
          console.log(_.filter(_this.volunteersData, {id: res.data.data.volunteers_id}));

          let allVolunteers = _this.volunteersData;
          let daysVolunteers = [];

          _.each(res.data.data, function (dailyReportItem) {
            console.log("dailyReportItem", dailyReportItem);
            daysVolunteers.push(_.filter(allVolunteers, {id: dailyReportItem.volunteers_id})[0]);
          });
          _this.volunteersData = daysVolunteers;

          if (!_this.reservationData.volunteerId && !_this.volunteer) {
            _this.volunteer = _this.volunteersData[0];
          }

          if (!_this.reservationData.carId && !_this.car) {
            _this.car = _this.carsData[0];
          }
          console.log("_this.car", _this.car);
          console.log("daysVolunteers", daysVolunteers);


        })
        .catch((error) => {
          console.log("error", error);
          return false
        });
    },
    async getReservation() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'reservations/' + _this.reservationId)
        .then((res) => {

          if (!res.data.data.departure) {
            res.data.data.departure = {
              quickSearch: null,
              route: null,
              streetNumber: null,
              postalCode: null,
              postalTown: null,
              municipality: null,
              country: null,
            }
          }

          if (!res.data.data.destination) {
            res.data.data.destination = {
              quickSearch: null,
              route: null,
              streetNumber: null,
              postalCode: null,
              postalTown: null,
              municipality: null,
              country: null,
            }
          }

          if (!res.data.data.arrival) {
            res.data.data.arrival = {
              quickSearch: null,
              route: null,
              streetNumber: null,
              postalCode: null,
              postalTown: null,
              municipality: null,
              country: null,
            }
          }


          _this.reservationData = res.data.data;
          _this.copyOfVolunteersData = _.cloneDeep(res.data.data);

          _this.customer = _.filter(_this.customersData, {id: res.data.data.customerId})[0];
          _this.volunteer = _.filter(_this.volunteersData, {id: res.data.data.volunteerId})[0];
          _this.reservationPurpose = res.data.data.purpose; //_.filter(_this.reservationPurposesData, {id: res.data.data.reservationPurpose})[0];
          _this.car = _.filter(_this.carsData, {id: res.data.data.carId})[0];
          _this.departureTime = _this.moment(res.data.data.departureTime).format('HH:mm');
          _this.arrivalTime = _this.moment(res.data.data.arrivalTime).format('HH:mm');
          _this.$forceUpdate();
          console.log("reservationData: ", res.data.data);
          console.log("customer: ", _this.customer);

          setTimeout(function () {
            directionsService = new google.maps.DirectionsService;
            directionsDisplay = new google.maps.DirectionsRenderer;
            directionsDisplay.setMap(_this.$refs.map.$mapObject);

            _this.setMapsDirections();


          }, 500)


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    saveReservation() {
      const _this = this;

      let reservation = _.omit(_this.reservationData, ['disabled', 'deleted', 'createdAt', 'updatedAt', 'customer', 'unit', 'volunteer', 'purpose', 'car']);
      // reservation.departure = reservation.departure;
      // reservation.destination = reservation.destination;
      // reservation.arrival = reservation.arrival;

      // reservation.departure =  {
      //   "quickSearch": reservation.departure.quickSearch,
      //     "route": reservation.departure.route,
      //     "streetNumber": reservation.departure.streetNumber,
      //     "postalCode": reservation.departure.postalCode,
      //     "postalTown": reservation.departure.postalTown,
      //     "municipality": reservation.departure.municipality,
      //     "country": reservation.departure.country,
      //     "addressName": reservation.departure.addressName,
      // };
      //
      // reservation.destination =  {
      //   "quickSearch": reservation.destination.quickSearch,
      //   "route": reservation.destination.route,
      //   "streetNumber": reservation.destination.streetNumber,
      //   "postalCode": reservation.destination.postalCode,
      //   "postalTown": reservation.destination.postalTown,
      //   "municipality": reservation.destination.municipality,
      //   "country": reservation.destination.country,
      //   "addressName": reservation.destination.addressName,
      // };
      //
      // reservation.arrival =  {
      //   "quickSearch": reservation.arrival.quickSearch,
      //   "route": reservation.arrival.route,
      //   "streetNumber": reservation.arrival.streetNumber,
      //   "postalCode": reservation.arrival.postalCode,
      //   "postalTown": reservation.arrival.postalTown,
      //   "municipality": reservation.arrival.municipality,
      //   "country": reservation.arrival.country,
      //   "addressName": reservation.arrival.addressName,
      // };

      let departureDate = _this.moment(_this.reservationData.departureTime).get('date');
      let departureMonth = _this.moment(_this.reservationData.departureTime).get('month');
      let departureYear = _this.moment(_this.reservationData.departureTime).get('year');
      let departureHour = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[0] : _this.moment(_this.departureTime).format('HH');
      let departureMinute = (_this.departureTime && _this.departureTime.toString().indexOf(':') > -1) ? _this.departureTime.split(':')[1] : _this.moment(_this.departureTime).format('mm');
      reservation.departureTime = _this.moment().set({
        'year': departureYear,
        'month': departureMonth,
        'date': departureDate,
        'hour': departureHour,
        'minute': departureMinute
      }).valueOf();


      let arrivalDate = _this.moment(_this.reservationData.departureTime).get('date');
      let arrivalMonth = _this.moment(_this.reservationData.departureTime).get('month');
      let arrivalYear = _this.moment(_this.reservationData.departureTime).get('year');
      let arrivalHour = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[0] : _this.moment(_this.arrivalTime).format('HH');
      let arrivalMinute = (_this.arrivalTime && _this.arrivalTime.toString().indexOf(':') > -1) ? _this.arrivalTime.split(':')[1] : _this.moment(_this.arrivalTime).format('mm');
      reservation.arrivalTime = _this.moment().set({
        'year': arrivalYear,
        'month': arrivalMonth,
        'date': arrivalDate,
        'hour': arrivalHour,
        'minute': arrivalMinute
      }).valueOf();
      _this.reservationData.arrivalTime = reservation.arrivalTime;

      reservation.customerId = _this.customer ? _this.customer.id : null;
      reservation.unitId = _this.$globalFunctions.getCurrentUnit().id;
      reservation.volunteerId = _this.volunteer ? _this.volunteer.id : null;

      // check if volunteer was selected before and is now removed
      console.log("---->", _this.copyOfVolunteersData, reservation.volunteerId);
      if (_this.copyOfVolunteersData && _this.copyOfVolunteersData.volunteer && _this.copyOfVolunteersData.volunteer.id && reservation.volunteerId === null) {
        reservation.volunteerId = -1;
      }

      reservation.reservationPurpose = _this.reservationPurpose ? _this.reservationPurpose.id : null;

      reservation.routeDistance = Math.round(_this.routeDistanceTotal / 1000);
      reservation.routeTime = _this.$globalFunctions.formatSecondsToReadableDuration(_this.routeTravelTimeTotal);

      // if (_this.carsData.length > 1) {
      reservation.carId = _this.car.id;
      // }
      reservation.waitingForReview = false;
      console.log("reservation data to post:", reservation);

      console.log("this.$v", this.$v);
      this.$v.$reset();
      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      _this.$vs.loading();

      if (!reservation.id) {
        Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'reservations', reservation)
          .then((res) => {

            console.log("res", res);

            if (_this.$route.params.intermodalReservationData) {
              let data = {
                reservation: res.data.data.id
              };

              Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.$route.params.intermodalReservationData.id, data)
                .then((_res) => {

                  console.log("_res", _res);
                  _this.$vs.loading.close();
                  _this.$router.push({name: "Home"});

                })
                .catch((error) => {
                  console.log("error", error);
                  _this.$vs.loading.close();
                  return false
                });

            } else {
              _this.$router.push({name: "Home"});
              _this.$vs.loading.close();
            }

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      } else {
        Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'reservations/' + reservation.id, reservation)
          .then((res) => {

            console.log("res", res);

            if (_this.$route.params.intermodalReservationData) {
              let data = {
                reservation: res.data.data.id
              };

              Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.$route.params.intermodalReservationData.id, data)
                .then((_res) => {

                  console.log("_res", _res);
                  _this.$vs.loading.close();
                  _this.$router.push({name: "Home"});

                })
                .catch((error) => {
                  console.log("error", error);
                  _this.$vs.loading.close();
                  return false
                });

            } else {
              _this.$router.push({name: "Home"});
              _this.$vs.loading.close();
            }

          })
          .catch((error) => {
            a
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      }

      console.log(JSON.stringify(this.reservationData));
      console.log(reservation);

    },


    async setMapsDirections() {
      const _this = this;

      await directionsService.route({
        origin: _this.reservationData.departure.quickSearch,
        waypoints: [{
          location: _this.reservationData.destination.quickSearch,
          stopover: true
        }],
        destination: _this.reservationData.arrival.quickSearch,
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING
      }, function (response, status) {
        if (status === 'OK') {
          console.log("response --->", response.routes);
          _this.routeDistanceAB = response.routes[0].legs[0].distance.value;
          _this.routeDistanceBC = response.routes[0].legs[1].distance.value;
          _this.routeDistanceTotal = response.routes[0].legs[0].distance.value + response.routes[0].legs[1].distance.value;

          _this.routeTravelTimeAB = response.routes[0].legs[0].duration.value;
          _this.routeTravelTimeBC = response.routes[0].legs[1].duration.value;
          _this.routeTravelTimeTotal = response.routes[0].legs[0].duration.value + response.routes[0].legs[1].duration.value;
          directionsDisplay.setDirections(response);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      });
    },

    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData ---->", addressData);
      console.log("addressSection", addressSection);

      console.log("_this.reservationData BEFORE: ", _this.reservationData);
      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.reservationData[addressSection].quickSearch = addressData.formatted_address;
      _this.reservationData[addressSection].route = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'route');
      _this.reservationData[addressSection].streetNumber = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'street_number');
      _this.reservationData[addressSection].postalTown = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'postal_town');
      _this.reservationData[addressSection].postalCode = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'postal_code');
      _this.reservationData[addressSection].municipality = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'administrative_area_level_1') ? Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'administrative_area_level_1') : Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'postal_town');
      _this.reservationData[addressSection].country = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'country');
      console.log("_this.reservationData AFTER: ", _this.reservationData);

      if (!directionsService) {
        directionsService = new google.maps.DirectionsService;
        directionsDisplay = new google.maps.DirectionsRenderer;
        directionsDisplay.setMap(_this.$refs.map.$mapObject);
      }

      _this.setMapsDirections();
      // _this.setMapsDirections();

      _this.$forceUpdate();
    },

    copyAddressName() {
      const _this = this;

      if (!_this.reservationData.arrival.addressName || _this.reservationData.arrival.addressName === '') {
        _this.reservationData.arrival.addressName = _.cloneDeep(_this.reservationData.departure.addressName);
        _this.$forceUpdate();
      }
    },

    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["first_name", "last_name", "addresses.quick_search"],
        minMatchCharLength: 3,
        shouldSort: true
      });

      return search.length
        ? fuse.search(search)
          .map(({item}) => (item))
        : results.list;
    },

    fuseSearchName(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name"],
        minMatchCharLength: 2,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    onDateChange() {
      const _this = this;
      _this.$vs.loading();

      _this.volunteer = null;
      _this.reservationData.volunteer = null;

      _this.getVolunteers().then(() => {
        _this.getDailyReports().then(() => {
          _this.$vs.loading.close();
        });
      });


    },

    onCustomerChange() {
      const _this = this;

      _this.reservationData.departure.addressName = _this.customer.addresses.address_name ? _this.customer.addresses.address_name : _this.customer.addresses.route;
      _this.reservationData.departure.country = _this.customer.addresses.country;
      _this.reservationData.departure.municipality = _this.customer.addresses.municipality;
      _this.reservationData.departure.postalCode = _this.customer.addresses.postal_code;
      _this.reservationData.departure.postalTown = _this.customer.addresses.postal_town;
      _this.reservationData.departure.quickSearch = _this.customer.addresses.quick_search;
      _this.reservationData.departure.route = _this.customer.addresses.route;
      _this.reservationData.departure.streetNumber = _this.customer.addresses.street_number;

      _this.reservationData.arrival = _.cloneDeep(_this.reservationData.departure);

      console.log("onCustomerChange", _this.customer);
    },

    beforeDeleteReservation() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati rezervacijo uporabnika ' + _this.reservationData.customer.firstName + ' ' + _this.reservationData.customer.lastName + ' ?',
        message: 'Po potrditvi bo rezervacija trajno izbrisana.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteReservation();
          }
        }
      });
    },

    deleteReservation() {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.delete(Vue.prototype.$config.api.productionOld + 'reservations/' + _this.reservationId)
        .then((res) => {

          _this.$router.go(-1);

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async prefillDataFromIntermodalReservation() {
      const _this = this;

      _this.$vs.loading();

      const intermodalReservationData = _this.$route.params.intermodalReservationData;
      const instructions = JSON.parse(intermodalReservationData.instructions)[0];

      console.log("intermodalReservationData", intermodalReservationData);
      console.log("instructions", instructions);
      console.log("_this.customer", _this.customer);

      // format reservation notes
      _this.customer = _.filter(_this.customersData, {id: intermodalReservationData.customer.id})[0]; //_.filter(_this.customersData, {id: intermodalReservationData.customer.id})[0];
      console.log("intermodal customer", _this.customer);
      _this.reservationData.reservationsNotes = 'Ta rezervacija se nanaša na intermodalni prevoz - kombinacija storitve Sopotniki in javnega potniškega prometa. ';
      _this.reservationData.reservationsNotes += _this.customer ? (_this.customer.gender === 'female' ? 'Gospa ' : 'Gospod ') : 'Potnik ';
      _this.reservationData.reservationsNotes += _this.customer ? (_this.customer.first_name + ' ' + _this.customer.last_name + ' ') : '';
      _this.reservationData.reservationsNotes += 'se z ';
      _this.reservationData.reservationsNotes += instructions.transit_details.line.vehicle.name === 'Avtobus' ? 'avtobusom ' : '';
      _this.reservationData.reservationsNotes += instructions.transit_details.line.vehicle.name === 'Vlak' ? 'vlakom ' : '';


      if (intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION') {
        _this.reservationData.reservationsNotes += 'z oznako ' + '\"' + instructions.transit_details.headsign + '\"' + ' odpravlja v/na ';
        _this.reservationData.reservationsNotes += '\"' + (intermodalReservationData.arrivalStop.addressName ? intermodalReservationData.arrivalStop.addressName : instructions.transit_details.arrival_stop.name) + '\"' + '. ';
        _this.reservationData.reservationsNotes += instructions.transit_details.line.vehicle.name + ' ima predviden odhod iz/s ' + '\"' + (intermodalReservationData.departureStop.addressName ? intermodalReservationData.departureStop.addressName : instructions.transit_details.departure_stop.name) + '\"';
        _this.reservationData.reservationsNotes += ' ob ' + (instructions.transit_details.departure_time ? instructions.transit_details.departure_time.text : '/') + '.';
      } else {
        _this.reservationData.reservationsNotes += 'z oznako ' + '\"' + instructions.transit_details.headsign + '\"' + ' vrača iz/s ';
        _this.reservationData.reservationsNotes += '\"' + (intermodalReservationData.departureStop.addressName ? intermodalReservationData.departureStop.addressName : instructions.transit_details.departure_stop.name) + '\"' + '. ';
        _this.reservationData.reservationsNotes += instructions.transit_details.line.vehicle.name + ' ima predviden prihod v/na ' + '\"' + (instructions.transit_details.arrival_stop.name ? instructions.transit_details.arrival_stop.name : 'postaje') + '\"';
        _this.reservationData.reservationsNotes += ' ob ' + (instructions.transit_details.arrival_time ? instructions.transit_details.arrival_time.text : '/') + '.';
      }


      // set departure and arrival addresses
      if (intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION') {
        // _this.reservationData.departure = _this.customer.addresses;
        _this.reservationData.departure.addressName = _this.customer.addresses.address_name ? _this.customer.addresses.address_name : _this.customer.addresses.route;
        _this.reservationData.departure.country = _this.customer.addresses.country;
        _this.reservationData.departure.municipality = _this.customer.addresses.municipality;
        _this.reservationData.departure.postalCode = _this.customer.addresses.postal_code;
        _this.reservationData.departure.postalTown = _this.customer.addresses.postal_town;
        _this.reservationData.departure.quickSearch = _this.customer.addresses.quick_search;
        _this.reservationData.departure.route = _this.customer.addresses.route;
        _this.reservationData.departure.streetNumber = _this.customer.addresses.street_number;

        _this.reservationData.destination.addressName = intermodalReservationData.departureStop.addressName ? intermodalReservationData.departureStop.addressName : '';
        _this.reservationData.destination.country = intermodalReservationData.departureStop.country;
        _this.reservationData.destination.municipality = intermodalReservationData.departureStop.municipality;
        _this.reservationData.destination.postalCode = intermodalReservationData.departureStop.postalCode;
        _this.reservationData.destination.postalTown = intermodalReservationData.departureStop.postalTown;
        _this.reservationData.destination.quickSearch = intermodalReservationData.departureStop.quickSearch;
        _this.reservationData.destination.route = intermodalReservationData.departureStop.route;
        _this.reservationData.destination.streetNumber = intermodalReservationData.departureStop.streetNumber;
        _this.reservationData.arrival = _this.reservationData.destination;

      } else if (intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME') {
        _this.reservationData.departure.addressName = intermodalReservationData.arrivalStop.addressName ? intermodalReservationData.arrivalStop.addressName : '';
        _this.reservationData.departure.country = intermodalReservationData.arrivalStop.country;
        _this.reservationData.departure.municipality = intermodalReservationData.arrivalStop.municipality;
        _this.reservationData.departure.postalCode = intermodalReservationData.arrivalStop.postalCode;
        _this.reservationData.departure.postalTown = intermodalReservationData.arrivalStop.postalTown;
        _this.reservationData.departure.quickSearch = intermodalReservationData.arrivalStop.quickSearch;
        _this.reservationData.departure.route = intermodalReservationData.arrivalStop.route;
        _this.reservationData.departure.streetNumber = intermodalReservationData.arrivalStop.streetNumber;

        _this.reservationData.destination.addressName = _this.customer.addresses.address_name ? _this.customer.addresses.address_name : _this.customer.addresses.route;
        _this.reservationData.destination.country = _this.customer.addresses.country;
        _this.reservationData.destination.municipality = _this.customer.addresses.municipality;
        _this.reservationData.destination.postalCode = _this.customer.addresses.postal_code;
        _this.reservationData.destination.postalTown = _this.customer.addresses.postal_town;
        _this.reservationData.destination.quickSearch = _this.customer.addresses.quick_search;
        _this.reservationData.destination.route = _this.customer.addresses.route;
        _this.reservationData.destination.streetNumber = _this.customer.addresses.street_number;

        _this.reservationData.arrival = _this.reservationData.destination;
      }

      directionsService = new google.maps.DirectionsService;
      directionsDisplay = new google.maps.DirectionsRenderer;
      directionsDisplay.setMap(_this.$refs.map.$mapObject);

      await _this.setMapsDirections();

      // set departure and arrival times
      setTimeout(function () {
        if (intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION') {
          // _this.reservationData.departureTime = intermodalReservationData.departureTime;
          console.log("_this.reservationData.departure_time", _this.reservationData.departure_time);

          let publicTransportationDepartureTime = _this.moment(parseInt(instructions.transit_details.departure_time.value + '000')).utc();
          publicTransportationDepartureTime = _this.moment(publicTransportationDepartureTime).subtract(_this.intermodalTransportationThreshold, 'seconds');
          publicTransportationDepartureTime = _this.moment(publicTransportationDepartureTime).subtract(_this.routeTravelTimeAB, 'seconds');
          publicTransportationDepartureTime = publicTransportationDepartureTime; //publicTransportationDepartureTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          _this.reservationData.departureTime = publicTransportationDepartureTime;
          _this.departureTime = publicTransportationDepartureTime.valueOf();
          console.log("!!!publicTransportationDepartureTime", publicTransportationDepartureTime);
          console.log("_this.routeTravelTimeAB", _this.routeTravelTimeAB);

          _this.arrivalTime = _this.moment(publicTransportationDepartureTime).add(_this.routeTravelTimeAB, 'seconds').valueOf();
          _this.reservationData.arrivalTime = _this.moment(publicTransportationDepartureTime).add(_this.routeTravelTimeAB, 'seconds');

        } else if (intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME') {
          // _this.reservationData.departureTime = intermodalReservationData.departureTime;

          let publicTransportationArrivalTime = _this.moment(parseInt(instructions.transit_details.arrival_time.value + '000')).utc();
          publicTransportationArrivalTime = publicTransportationArrivalTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          _this.reservationData.departureTime = publicTransportationArrivalTime;
          _this.departureTime = publicTransportationArrivalTime.valueOf();
          _this.arrivalTime = _this.moment(publicTransportationArrivalTime).add(_this.routeTravelTimeAB, 'seconds').valueOf();
          _this.reservationData.arrivalTime = _this.moment(publicTransportationArrivalTime).add(_this.routeTravelTimeAB, 'seconds');
        }
        _this.onDateChange();
        _this.$forceUpdate();
      }, 200);
    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert').getBoundingClientRect();
      window.scrollTo(
        domRect.left + document.documentElement.scrollLeft,
        domRect.top + document.documentElement.scrollTop - 200, 'smooth'
      );
    },
  }
}
</script>

