<template>
  <div id="reservationContainer">
    <ReservationEdit :reservationId="reservationId"></ReservationEdit>
  </div>
</template>

<script>
  import Vue from 'vue';
  import ReservationEdit from "../../components/Sopotniki/reservations/ReservationEdit";

  export default {
    name: 'Reservation',

    components: {
      ReservationEdit
    },

    data() {
      return {
        reservationId: this.$route.params.reservationId ? parseInt(this.$route.params.reservationId) : null,
      }
    },

    computed: {},

    mounted() {
      const _this = this;
      // _this.getReservation();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.reservationId = null;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      // getReservation() {
      //   const _this = this;
      //
      //   _this.$vs.loading();
      // },

    }
  }
</script>

